var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-content" },
    [
      _vm.otherPinned
        ? [
            _c("p", { staticClass: "starling-explanatory mb-2" }, [
              _vm._v(
                _vm._s(_vm.$t("app.tools.goal_setter.goal_card.pin_replace")[0])
              )
            ]),
            _c("p", {
              staticClass: "starling-explanatory mb-2",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("app.tools.goal_setter.goal_card.pin_replace")[1]
                )
              }
            })
          ]
        : [
            _c("p", { staticClass: "starling-explanatory mb-2" }, [
              _vm._v(
                _vm._s(_vm.$t("app.tools.goal_setter.goal_card.pin_first"))
              )
            ])
          ],
      _c("action-buttons", {
        attrs: {
          primary: {
            label: _vm.$t("common.actions.pin"),
            disabled: false,
            loading: false
          },
          secondary: {
            label: _vm.$t("common.actions.cancel"),
            disabled: false,
            loading: false
          }
        },
        on: {
          "primary-clicked": _vm.actionClick,
          "secondary-clicked": _vm.closeModal
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }