


































































/* eslint-disable security/detect-non-literal-require */
import Vue from 'vue';
import { GoalStatusEnum, IGoalSetter } from '@/scripts/store/modules/tools/goals/types';
import goalCardHeader from '@/views/tools/goals/components/goal-card-header.vue';
import goalCardModal from '@/views/tools/goals/components/card-modal/goal-card-modal.vue';
import goalCardContent from '@/views/tools/goals/components/goal-card-content.vue';
import moment from 'moment';

export default Vue.extend({
  name: 'goalCard',
  components: {
    goalCardHeader,
    goalCardModal,
    goalCardContent,
  },
  props: {
    goal: {
      type: Object as () => IGoalSetter,
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeModalView: undefined as string | undefined,
      modalActive: false,
      panel: null as number | null,
    };
  },
  computed: {
    isStarted(): boolean {
      return this.goal.status === GoalStatusEnum.STARTED;
    },
    isPaused(): boolean {
      return this.goal.status === GoalStatusEnum.PAUSED;
    },
    isCompleted(): boolean {
      return this.goal.status === GoalStatusEnum.COMPLETED;
    },
    headerImage(): any {
      if (!this.showHeader || this.embedded) return;
      const index = (moment().dayOfYear() % 6) + 1;
      return require(`@/assets/svg/bird_goal_cheer_${index}.svg`);
    },
    headerContent(): string | undefined {
      if (!this.showHeader) return;
      const motivational = this.$t('app.tools.goal_setter.goal_card.motivational') as any as string[];
      const index = moment().dayOfYear() % motivational.length;
      return motivational[index];
    },
  },
  methods: {
    togglePanel() {
      if (this.panel === 0) {
        this.panel = null;
        this.closeModal();
      } else {
        this.panel = 0;
      }
    },
    toggleModal(viewName: string) {
      if (viewName === 'edit') {
        this.onEdit();
        return;
      }
      if (viewName === 'resume') {
        this.onResume();
        return;
      }
      this.panel = 0;
      this.activeModalView = viewName;
      this.modalActive = true;
    },
    closeModal() {
      if (this.isPaused) {
        this.panel = null;
      }

      this.modalActive = false;
      this.activeModalView = undefined;
    },
    onComplete() {
      this.$store.dispatch('goals/completeGoal', this.goal).then(res => {
        this.closeModal();
        this.$emit('update', res);
      }, () => {
        this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
      });
    },
    onDelete() {
      this.$store.dispatch('goals/deleteGoal', this.goal.id).then(() => {
        this.closeModal();
        this.$emit('update', undefined);
      }, () => {
        this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
      });
    },
    onDuplicate() {
      const goalCopy = Object.assign({}, this.goal);
      goalCopy.id = undefined;
      goalCopy.status = GoalStatusEnum.INITIAL;
      goalCopy.completedPercentage = 0;
      goalCopy.pinned = false;

      goalCopy.title = this.$t('app.tools.goal_setter.fields.title.copy') + goalCopy.title;
      if (goalCopy.title.length > 255) goalCopy.title.substring(0, 255);

      goalCopy.tasks.forEach(task => {
        task.id = '';
        task.completed = false;
      });

      this.$router.push({ name: 'goal-duplicate', params: { goal: JSON.stringify(goalCopy) } });
    },
    onEdit() {
      this.closeModal();
      this.$emit('edit', this.goal);
    },
    onPin() {
      this.$store.dispatch('goals/pinGoal', this.goal).then(res => {
        this.closeModal();
        this.$emit('update', res);
      }, () => {
        this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
      });
    },
    onUnpin() {
      this.$store.dispatch('goals/unpinGoal', this.goal).then(res => {
        this.closeModal();
        this.$emit('update', res);
      }, () => {
        this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
      });
    },
    onPause() {
      this.$store.dispatch('goals/pauseGoal', this.goal).then(res => {
        this.closeModal();
        this.$emit('update', res);
      }, () => {
        this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
      });
    },
    onResume() {
      this.$store.dispatch('goals/resumeGoal', this.goal).then(res => {
        this.closeModal();
        this.$emit('update', res);
      }, () => {
        this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
      });
    },
    onUpdate(goal: IGoalSetter) {
      this.$store.dispatch('goals/editGoal', goal)
        .then(res => {
          this.$emit('update', res);
        }, () => {
          this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
        });
    },
  },
});
