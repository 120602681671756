















































































import Vue from 'vue';
import { GoalRepeatFrequencyEnum, GoalStatusEnum, IGoalSetter } from '@/scripts/store/modules/tools/goals/types';
import { format } from 'date-fns';
import store from '@/scripts/store';
import moment from 'moment';
import slider from '@/views/components/slider.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'goal-card-content',
  components: { slider, PrimaryButton },
  props: {
    goal: {
      type: Object as () => IGoalSetter,
      required: true,
    },
    toggleModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      internalGoal: Object.assign({}, this.goal),
    };
  },
  computed: {
    allowEdit(): boolean {
      return this.internalGoal.status !== GoalStatusEnum.PAUSED;
    },
    allowComplete(): boolean {
      return this.internalGoal.status !== GoalStatusEnum.PAUSED && this.internalGoal.status !== GoalStatusEnum.COMPLETED && this.internalGoal.completedPercentage === 100;
    },
    completedPercentage(): number {
      return this.internalGoal.completedPercentage ? Math.floor(this.internalGoal.completedPercentage / 10) : 0;
    },
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    abbreviatedDateFormat() {
      return store.getters.getAbbreviatedDateFormat;
    },
    fullDateFormat(): string {
      return `${this.currentLocale.formatLong?.time({ width: 'short' })}, ${this.abbreviatedDateFormat}`;
    },
    showTasks(): boolean {
      return !this.internalGoal.visibleFields || this.internalGoal.visibleFields.some(field => field === 'tasks' || field === 'description');
    },
    showDate(): boolean {
      return !this.internalGoal.visibleFields || this.internalGoal.visibleFields.some(field => field === 'date');
    },
    showTime(): boolean {
      return !this.internalGoal.visibleFields || this.internalGoal.visibleFields.some(field => field === 'time');
    },
    showReminder(): boolean {
      return !this.internalGoal.visibleFields || this.internalGoal.visibleFields.some(field => field === 'reminder');
    },
    showRepeat(): boolean {
      return !this.internalGoal.visibleFields || this.internalGoal.visibleFields.some(field => field === 'repeat');
    },
    intervalOptions(): Array<{ value: number, text: string }> {
      return this.$t('app.tools.goal_setter.fields.reminder.intervalOptions') as any as { value: number, text: string }[];
    },
    weekDays(): Array<{ value: string, text: string, label: string }> {
      return this.$t('app.tools.goal_setter.fields.repeat.weekDays') as any as { value: string, text: string, label: string }[];
    },
    interval(): string | undefined {
      return this.intervalOptions.find(options => options.value === this.internalGoal.reminderInterval)?.text;
    },
    formattedStartDate(): string | undefined {
      if (!this.internalGoal.startDate) return;
      return format(new Date(this.internalGoal.startDate), this.fullDateFormat, { locale: this.currentLocale });
    },
    formattedRepeat(): string | undefined {
      if (!this.internalGoal.repeatFrequency || !this.internalGoal.repeatFrequency.length) return;
      if (this.internalGoal.repeatFrequency[0] === GoalRepeatFrequencyEnum.DAILY) {
        return this.$t('app.tools.goal_setter.goal_card.content.daily') as string;
      }
      const days = this.internalGoal.repeatFrequency.map(day => this.weekDays.find(weekday => weekday.value === day)?.label).join(', ');
      return this.$t('app.tools.goal_setter.goal_card.content.weekly', { days }) as string;
    },
  },
  methods: {
    formatDate(timestamp: string) {
      return moment(timestamp).format('LL');
    },
    formatTime(timestamp: string) {
      return moment(timestamp).format('LT');
    },
    onSliderChange(newValue: string) {
      this.internalGoal.completedPercentage = parseInt(newValue) * 10;
      this.$emit('update', this.internalGoal);
    },
    onTaskCheckboxClick() {
      const individualTaskAmount = 100 / this.internalGoal.tasks.length;
      const totalPercentage = this.internalGoal.tasks.filter(task => task.completed).length * individualTaskAmount;

      if (totalPercentage > this.internalGoal.completedPercentage) {
        this.internalGoal.completedPercentage = totalPercentage;
      }
      this.$emit('update', this.internalGoal);
    },
    completeGoal() {
      this.$emit('complete');
    },
  },
});
