var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goal-card" },
    [
      _vm.showHeader
        ? _c(
            "v-layout",
            { staticClass: "header", attrs: { row: "", "align-center": "" } },
            [
              _vm.headerImage
                ? _c(
                    "v-flex",
                    { attrs: { xs2: "", lg1: "", "align-self-end": "" } },
                    [
                      _c("img", {
                        staticClass: "d-block",
                        attrs: { src: _vm.headerImage, alt: "" }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.headerContent
                ? _c(
                    "v-flex",
                    { class: _vm.headerImage ? "xs10 lg11" : "xs12 pl-2" },
                    [
                      _c("p", { staticClass: "starling-text py-2 pr-2" }, [
                        _c("em", [_vm._v(_vm._s(_vm.headerContent))])
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-expansion-panel",
        {
          staticClass: "elevation-0",
          class: {
            paused: _vm.isPaused,
            recommended: _vm.isStarted,
            completed: _vm.isCompleted
          },
          attrs: { value: _vm.panel }
        },
        [
          _c(
            "v-expansion-panel-content",
            {
              attrs: { readonly: "", disabled: _vm.isPaused },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          attrs: { flat: "", icon: "" },
                          on: { click: _vm.togglePanel }
                        },
                        [
                          _c("v-icon", { attrs: { medium: "" } }, [
                            _vm._v("$vuetify.icons.expand")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "expansion-header-title",
                          on: { click: _vm.togglePanel }
                        },
                        [
                          _c(
                            "h4",
                            {
                              staticClass: "starling-h4",
                              class: {
                                "StarlingPrimary2--text": _vm.isCompleted,
                                "fade-overflow": _vm.panel !== 0
                              }
                            },
                            [
                              _vm.isPaused
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "StarlingDarkblue",
                                        size: "1.5em"
                                      }
                                    },
                                    [_vm._v("pause_circle_filled")]
                                  )
                                : _vm.isCompleted
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "StarlingSuccess",
                                        size: "1.5em"
                                      }
                                    },
                                    [_vm._v("check_circle")]
                                  )
                                : _c("custom-icon", {
                                    attrs: {
                                      name: "goal",
                                      size: "md",
                                      stroke: "var(--StarlingPrimary2)"
                                    }
                                  }),
                              _c(
                                "span",
                                { staticClass: "pl-1", staticStyle: {} },
                                [_vm._v(_vm._s(_vm.goal.title))]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("goal-card-header", {
                        staticClass: "expansion-header-options",
                        attrs: {
                          goal: _vm.goal,
                          toggleModal: _vm.toggleModal,
                          activeModalView: _vm.activeModalView
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-card",
                { staticClass: "goal-card-details" },
                [
                  _c("goal-card-modal", {
                    staticClass: "scale-in-center",
                    attrs: {
                      goal: _vm.goal,
                      modalActive: _vm.modalActive,
                      activeModalView: _vm.activeModalView,
                      closeModal: _vm.closeModal
                    },
                    on: {
                      edit: _vm.onEdit,
                      pause: _vm.onPause,
                      resume: _vm.onResume,
                      pin: _vm.onPin,
                      unpin: _vm.onUnpin,
                      delete: _vm.onDelete,
                      duplicate: _vm.onDuplicate,
                      complete: _vm.onComplete
                    }
                  }),
                  _c("goal-card-content", {
                    staticClass: "scale-in-center",
                    class: { hidden: _vm.modalActive },
                    attrs: { goal: _vm.goal, toggleModal: _vm.toggleModal },
                    on: { update: _vm.onUpdate, complete: _vm.onComplete }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }