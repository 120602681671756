<template>
<div class="modal-content">
  <template v-if="otherPinned">
    <p class="starling-explanatory mb-2">{{ $t('app.tools.goal_setter.goal_card.pin_replace')[0] }}</p>
    <p class="starling-explanatory mb-2" v-html="$t('app.tools.goal_setter.goal_card.pin_replace')[1]"></p>
  </template>
  <template v-else>
    <p class="starling-explanatory mb-2">{{ $t('app.tools.goal_setter.goal_card.pin_first') }}</p>
  </template>
  <action-buttons
          :primary="{label : $t('common.actions.pin'), disabled : false, loading : false}"
          @primary-clicked="actionClick"
          :secondary="{label : $t('common.actions.cancel'),disabled : false, loading : false}"
          @secondary-clicked="closeModal">
  </action-buttons>
</div>
</template>

<script>
import actionButtons from '@/views/components/navigation/action-buttons.vue';

export default {
  name: 'goalCardPin',
  components: {
    actionButtons,
  },
  props: [ 'goal', 'closeModal' ],
  data() {
    return {
      otherPinned: false,
    };
  },
  mounted() {
    this.$store.dispatch('goals/getPinnedGoal').then((goal) => {
      if (goal?.id) {
        this.otherPinned = true;
      }
    });
  },
  methods: {
    actionClick() {
      this.$emit('action');
    },
  },
};
</script>
