var render = function() {
  var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5, _obj$6
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "card-modal",
      class:
        ((_obj = {}),
        (_obj["modal-active " + _vm.activeModalView] = _vm.modalActive),
        _obj)
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-2", class: { completed: _vm.goal.completed } },
        [
          _vm.activeModalView === "pause"
            ? _c("goal-card-pause", {
                class:
                  ((_obj$1 = {}),
                  (_obj$1["content-active"] = _vm.modalActive),
                  _obj$1),
                attrs: {
                  goal: _vm.goal,
                  modalActive: _vm.modalActive,
                  closeModal: _vm.closeModal
                },
                on: { action: _vm.onAction }
              })
            : _vm._e(),
          _vm.activeModalView === "pin"
            ? _c("goal-card-pin", {
                class:
                  ((_obj$2 = {}),
                  (_obj$2["content-active"] = _vm.modalActive),
                  _obj$2),
                attrs: { goal: _vm.goal, closeModal: _vm.closeModal },
                on: { action: _vm.onAction }
              })
            : _vm._e(),
          _vm.activeModalView === "unpin"
            ? _c("goal-card-unpin", {
                class:
                  ((_obj$3 = {}),
                  (_obj$3["content-active"] = _vm.modalActive),
                  _obj$3),
                attrs: { goal: _vm.goal, closeModal: _vm.closeModal },
                on: { action: _vm.onAction }
              })
            : _vm._e(),
          _vm.activeModalView === "delete"
            ? _c("goal-card-delete", {
                class:
                  ((_obj$4 = {}),
                  (_obj$4["content-active"] = _vm.modalActive),
                  _obj$4),
                attrs: { goal: _vm.goal, closeModal: _vm.closeModal },
                on: { action: _vm.onAction }
              })
            : _vm._e(),
          _vm.activeModalView === "duplicate"
            ? _c("goal-card-duplicate", {
                class:
                  ((_obj$5 = {}),
                  (_obj$5["content-active"] = _vm.modalActive),
                  _obj$5),
                attrs: { goal: _vm.goal, closeModal: _vm.closeModal },
                on: { action: _vm.onAction }
              })
            : _vm._e(),
          _vm.activeModalView === "complete"
            ? _c("goal-card-complete", {
                class:
                  ((_obj$6 = {}),
                  (_obj$6["content-active"] = _vm.modalActive),
                  _obj$6),
                attrs: {
                  goal: _vm.goal,
                  modalActive: _vm.modalActive,
                  closeModal: _vm.closeModal
                },
                on: { action: _vm.onAction }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }