<template>
<span class="card-modal" :class="{[`modal-active ${activeModalView}`]: modalActive}">
  <v-card-text class="pa-2" :class="{'completed': goal.completed}">

    <!-- <goal-card-edit
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'edit'"
            :goal="goal"
            :modalActive="modalActive"
            :closeModal="closeModal"
            @action="onAction">
    </goal-card-edit> -->

    <goal-card-pause
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'pause'"
            :goal="goal"
            :modalActive="modalActive"
            :closeModal="closeModal"
            @action="onAction">
    </goal-card-pause>

    <goal-card-pin
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'pin'"
            :goal="goal"
            :closeModal="closeModal"
            @action="onAction">
    </goal-card-pin>

    <goal-card-unpin
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'unpin'"
            :goal="goal"
            :closeModal="closeModal"
            @action="onAction">
    </goal-card-unpin>

    <goal-card-delete
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'delete'"
            :goal="goal"
            :closeModal="closeModal"
            @action="onAction">
    </goal-card-delete>

    <goal-card-duplicate
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'duplicate'"
            :goal="goal"
            :closeModal="closeModal"
            @action="onAction">
    </goal-card-duplicate>

    <goal-card-complete
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'complete'"
            :goal="goal"
            :modalActive="modalActive"
            :closeModal="closeModal"
            @action="onAction">
    </goal-card-complete>

  </v-card-text>
</span>
</template>

<script>
// import goalCardEdit from '@/views/tools/goals/components/card-modal/goal-card-edit.vue';
import goalCardPin from '@/views/tools/goals/components/card-modal/goal-card-pin.vue';
import goalCardUnpin from '@/views/tools/goals/components/card-modal/goal-card-unpin.vue';
import goalCardDelete from '@/views/tools/goals/components/card-modal/goal-card-delete.vue';
import goalCardDuplicate from '@/views/tools/goals/components/card-modal/goal-card-duplicate.vue';
import goalCardComplete from '@/views/tools/goals/components/card-modal/goal-card-complete.vue';
import goalCardPause from '@/views/tools/goals/components/card-modal/goal-card-pause.vue';

export default {
  name: 'goalCardModal',
  components: {
    // goalCardEdit,
    goalCardPin,
    goalCardUnpin,
    goalCardDelete,
    goalCardDuplicate,
    goalCardComplete,
    goalCardPause,
  },
  props: [ 'goal', 'activeModalView', 'modalActive', 'closeModal' ],
  methods: {
    onAction() {
      this.$emit(this.activeModalView);
    },
  },
};
</script>

<style lang="scss">
.card-modal {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  &.modal-active {
    overflow: auto;
  }
}
</style>
