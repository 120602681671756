var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-card-text",
        { staticClass: "pa-2" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("h4", { staticClass: "starling-h4" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("app.tools.goal_setter.goal_card.content.progress")
                    )
                  )
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "", "mt-1": "", "px-2": "" } },
                [
                  _c("slider", {
                    attrs: {
                      value: _vm.completedPercentage,
                      min: 0,
                      max: 10,
                      tickLabels: false,
                      showThumbLabel: false,
                      alwaysDirty: false,
                      color: "primary",
                      disabled: !_vm.allowEdit
                    },
                    on: { change: _vm.onSliderChange }
                  }),
                  _c(
                    "v-layout",
                    { attrs: { "no-wrap": "", "pb-1": "" } },
                    [
                      _c("v-flex", { attrs: { xs4: "", "text-left": "" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-semi-bold",
                            staticStyle: { "margin-left": "-5px" }
                          },
                          [_vm._v("0%")]
                        )
                      ]),
                      _c("v-flex", { attrs: { xs4: "", "text-center": "" } }, [
                        _c("span", { staticClass: "font-weight-semi-bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "app.tools.goal_setter.goal_card.content.slider_progress"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs4: "", "text-right": "" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-semi-bold",
                            staticStyle: { "margin-right": "-20px" }
                          },
                          [_vm._v("100%")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showTasks
                ? _vm._l(_vm.internalGoal.tasks, function(task, index) {
                    return _c(
                      "v-flex",
                      {
                        key: index,
                        attrs: { xs12: "", "mt-1": "", "px-1": "" }
                      },
                      [
                        _c("v-checkbox", {
                          staticClass: "mt-0",
                          attrs: {
                            "hide-details": "",
                            color: "StarlingPrimary2",
                            disabled: !_vm.allowEdit
                          },
                          on: { change: _vm.onTaskCheckboxClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "strong",
                                      {
                                        staticClass:
                                          "StarlingDarkblue--text ml-1"
                                      },
                                      [_vm._v(_vm._s(task.description))]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: task.completed,
                            callback: function($$v) {
                              _vm.$set(task, "completed", $$v)
                            },
                            expression: "task.completed"
                          }
                        })
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "", "mt-2": "", "mb-1": "", "px-1": "" } },
                [_c("v-divider")],
                1
              ),
              _vm.showDate && _vm.showTime
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-1": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "align-baseline": "" } },
                        [
                          _c("v-flex", { attrs: { shrink: "" } }, [
                            _c("h4", { staticClass: "starling-h4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.tools.goal_setter.goal_card.content.start"
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { "pl-3": "" } }, [
                            _c("p", { staticClass: "starling-explanatory" }, [
                              _vm._v(_vm._s(_vm.formattedStartDate))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showReminder
                ? [
                    _vm.interval
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-1": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", "align-baseline": "" } },
                              [
                                _c("v-flex", { attrs: { shrink: "" } }, [
                                  _c("h4", { staticClass: "starling-h4" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "app.tools.goal_setter.goal_card.content.reminder"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _c("v-flex", { attrs: { "pl-3": "" } }, [
                                  _c(
                                    "p",
                                    { staticClass: "starling-explanatory" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "app.tools.goal_setter.goal_card.content.before",
                                            { interval: _vm.interval }
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.interval && _vm.formattedRepeat && _vm.showRepeat
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-1": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", "align-baseline": "" } },
                              [
                                _c("v-flex", { attrs: { shrink: "" } }, [
                                  _c("h4", { staticClass: "starling-h4" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "app.tools.goal_setter.goal_card.content.repeat"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _c("v-flex", { attrs: { "pl-3": "" } }, [
                                  _c(
                                    "p",
                                    { staticClass: "starling-explanatory" },
                                    [_vm._v(_vm._s(_vm.formattedRepeat))]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.allowComplete
                ? _c(
                    "v-flex",
                    {
                      staticClass: "text-center",
                      attrs: { xs12: "", "mt-2": "" }
                    },
                    [
                      _c(
                        "primary-button",
                        { on: { click: _vm.completeGoal } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("common.actions.done")) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }