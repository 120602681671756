

























































import Vue from 'vue';
import { GoalStatusEnum, IGoalSetter } from '@/scripts/store/modules/tools/goals/types';

export default Vue.extend({
  name: 'goal-card-header',
  props: {
    goal: {
      type: Object as () => IGoalSetter,
      required: true,
    },
    toggleModal: {
      type: Function,
      required: true,
    },
    activeModalView: {
      type: String,
      required: false,
    },
  },
  computed: {
    showEdit(): boolean {
      return this.goal.status !== GoalStatusEnum.PAUSED;
    },
    showPause(): boolean {
      return this.goal.status === GoalStatusEnum.STARTED;
    },
    showResume(): boolean {
      return this.goal.status === GoalStatusEnum.PAUSED;
    },
    showPin(): boolean {
      return !this.goal.pinned && (this.goal.status === GoalStatusEnum.INITIAL || this.goal.status === GoalStatusEnum.STARTED);
    },
    showUnpin(): boolean {
      return this.goal.pinned && (this.goal.status === GoalStatusEnum.INITIAL || this.goal.status === GoalStatusEnum.STARTED);
    },
  },
});
